import { getCommonColumns } from "Components/CustomTable/Commons";
import CustomTable from "Components/CustomTable/CustomTable";
import AddEditForm from "Components/Forms/Master/Invoices/AddEditForm";
// import AddEditForm from "Components/Forms/Master/Invoice/AddEditForm";
import getPermittedColumns from "Utils/getPermittedColumns";
import { useSelector } from "react-redux";

const apiUrl = "/order/invoices/";
const headerTitle = "Invoices Master";
const editPermission = "invoices:edit";
const deletePermission = "invoices:delete";
const addPermission = "invoices:add";
const columns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    // render: (text) => <a>{text}</a>,
  },
  {
    title: "Order",
    dataIndex: "order",
    key: "order",
    // render: (text) => <a>{text}</a>,
  },
  {
    title: "Payment Status",
    dataIndex: ["payment_status"],
    key: "payment_status",
    // render: (text) => <a>{text}</a>,
  },
  {
    title: "Percent GST",
    dataIndex: ["percent_gst"],
    key: "percent_gst",
    // render: (text) => <a>{text}</a>,
  },
  {
    title: "Percentage Pay",
    dataIndex: ["percentage_pay"],
    key: "percentage_pay",
    // render: (text) => <a>{text}</a>,
  },
  {
    title: "Step",
    dataIndex: ["step"],
    key: "step",
    // render: (text) => <a>{text}</a>,
  },
  {
    title: "Step Description",
    dataIndex: ["step description"],
    key: "step description",
    // render: (text) => <a>{text}</a>,
  },
  ...getCommonColumns({
    apiUrl,
    editPermission,
    deletePermission,
    created: true,
    updated: true,
    action: true,
    isInvoicePage: true,
  }),
];

const InvoicesPage = () => {
  const userPermissions = useSelector(
    (store) => store.auth?.user?.user_permissions
  );
  let _columns = getPermittedColumns(
    columns,
    userPermissions,
    editPermission,

    deletePermission
  );

  return (
    <CustomTable
      columns={_columns}
      apiUrl={apiUrl}
      headerTitle={headerTitle}
      MyFormComponent={AddEditForm}
      addPermissionNames={[addPermission]}
    />
  );
};

export default InvoicesPage;
