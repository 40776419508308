import { getCommonColumns } from "Components/CustomTable/Commons";
import CustomTable from "Components/CustomTable/CustomTable";
import AddEditForm from "Components/Forms/Master/Order/AddEditForm";
import getPermittedColumns from "Utils/getPermittedColumns";
import { useSelector } from "react-redux";

const apiUrl = "/order/orders/";
const headerTitle = "Orders Master";
const editPermission = "orders:edit";
const deletePermission = "orders:delete";
const addPermission = "orders:add";
const columns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    // render: (text) => <a>{text}</a>,
  },
  {
    title: "Type",
    dataIndex: "project_type_data",
    key: "project_type_data",
    // render: (text) => <a>{text}</a>,
  },
  // {
  //   title: "Order Inspiration",
  //   dataIndex: "order_inspiration",
  //   key: "order_inspiration",
  //   // render: (text) => <a>{text}</a>,
  // },
  {
    title: "Quoted Amount",
    dataIndex: "quoted_amount",
    key: "quoted_amount",
    // render: (text) => <a>{text}</a>,
  },
  {
    title: "Current Step",
    dataIndex: "current_step",
    key: "current_step",
    // render: (text) => <a>{text}</a>,
  },
  {
    title: "Service Type",
    dataIndex: "service_type",
    key: "service_type",
    // render: (text) => <a>{text}</a>,
  },
  {
    title: "Floorplan",
    dataIndex: "floorplan",
    key: "floorplan",
    // render: (text) => <a>{text}</a>,
  },
  {
    title: "Requirement",
    dataIndex: "requirement",
    key: "requirement",
    // render: (text) => <a>{text}</a>,
  },
  {
    title: "Selected Rooms",
    dataIndex: "selected_rooms",
    key: "selected_rooms",
    // render: (text) => <a>{text}</a>,
  },
  {
    title: "Area",
    dataIndex: "area",
    key: "area",
    // render: (text) => <a>{text}</a>,
  },
  {
    title: "Area",
    dataIndex: "area",
    key: "area",
    // render: (text) => <a>{text}</a>,
  },
  // {
  //   title: "User",
  //   dataIndex: "user",
  //   key: "user",
  //   // render: (text) => <a>{text}</a>,
  // },

  ...getCommonColumns({
    apiUrl,
    editPermission,
    deletePermission,
    created: true,
    updated: true,
    action: true,
    isOrderPage: true,
  }),
];

const OrdersPage = () => {
  const userPermissions = useSelector(
    (store) => store.auth?.user?.user_permissions
  );
  let _columns = getPermittedColumns(
    columns,
    userPermissions,
    editPermission,
    deletePermission
  );

  return (
    <CustomTable
      columns={_columns}
      apiUrl={apiUrl}
      headerTitle={headerTitle}
      MyFormComponent={AddEditForm}
      addPermissionNames={[addPermission]}
    />
  );
};

export default OrdersPage;
