import { getCommonColumns } from "Components/CustomTable/Commons";
import CustomTable from "Components/CustomTable/CustomTable";
import AddEditForm from "Components/Forms/Marketing/ReviewRatings/AddEditForm";
import getPermittedColumns from "Utils/getPermittedColumns";
import { useSelector } from "react-redux";

const apiUrl = "/marketing/review-ratings";
const headerTitle = "Testimonials";
const editPermission = "reviewratings:edit";
const deletePermission = "reviewratings:delete";
const addPermission = "reviewratings:add"
const columns = [
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
    },
    {
        title: 'For User',
        dataIndex: ['for_user_data', 'full_name'],
        key: 'for_user',
    },
    {
        title: 'By User',
        dataIndex: ['by_user_data', 'full_name'],
        key: 'by_user',
    },
    {
        title: 'Rating',
        dataIndex: 'rating',
        key: 'rating',
    },
    ...getCommonColumns({ apiUrl, editPermission, deletePermission, created: true, updated: true, action: true })
]

const ReviewRatingsPage = () => {

    const userPermissions = useSelector(store => store.auth?.user?.user_permissions);
    let _columns = getPermittedColumns(columns, userPermissions, editPermission, deletePermission);

    return <CustomTable
        columns={_columns}
        apiUrl={apiUrl}
        headerTitle={headerTitle}
        MyFormComponent={AddEditForm}
        addPermissionNames={[addPermission]}
    />;
}

export default ReviewRatingsPage;