import { Button, Flex } from "antd";
import React from "react";
import { Link } from "react-router-dom";

const DashboardPage = () => {
  return (
    <Flex
      vertical
      align="center"
      justify="center"
      style={{ width: "100%", height: "100%", gap: 20 }}
    >
      <Link to={"/"}>
        <Button type="dashed">Interiokeys</Button>
      </Link>
    </Flex>
  );
};

export default DashboardPage;
