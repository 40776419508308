import React, { useEffect, useState } from "react";
import { Button, Form, Input, notification, Select, InputNumber } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  addARecord,
  editARecord,
  fetchARecord,
} from "Redux/MasterReducer/crudSlices";
// import apiUrls from 'Utils/apiUrls';
import { FormModesEnum, asyncStatuses } from "Redux/enums";
import { LoadingOutlined } from "@ant-design/icons";
import axios from "axios";
import { fetchUserList } from "Api/commonApis";
const { Option } = Select;

const FLOORPLAN_CHOICES = [
  { value: "2bhk", label: "2bhk" },
  { value: "2.5bhk", label: "2.5bhk" },
  { value: "3bhk", label: "3bhk" },
  { value: "3.5bhk", label: "3.5bhk" },
  { value: "4bhk", label: "4bhk" },
  { value: "5bhk", label: "5bhk" },
];

const REQUIREMENT_CHOICES = [
  { value: "complete_home", label: "Complete Home" },
  { value: "selected_rooms", label: "Selected Rooms" },
];

const REQUIREMENT_TYPE_CHOICES = [
  { value: "turnkey", label: "Turnkey (Complete) Interior Solution" },
  { value: "customised", label: "Customised Interior Drawings" },
];

const AddEditForm = ({ apiUrl, orderInspiration }) => {
  const [notificationApi, contextHolder] = notification.useNotification();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [image, setImage] = useState(null);
  const [users, setUsers] = useState(null);

  const openNotification = (message, description, type) => {
    notificationApi[type]({
      message: message,
      description: description,
      placement: "top",
    });
  };

  const currentFormMode = useSelector((store) => store.master?.currentFormMode);
  const currentEditViewFormId = useSelector(
    (store) => store.master?.currentEditViewFormId
  );
  const fetchRecordStatus = useSelector(
    (store) => store.master?.fetchRecordStatus
  );
  const editRecordStatus = useSelector(
    (store) => store.master?.editRecordStatus
  );
  const addRecordStatus = useSelector((store) => store.master?.addRecordStatus);
  const currentRecordData = useSelector(
    (store) => store.master?.currentRecordData
  );

  useEffect(() => {
    if (editRecordStatus === asyncStatuses.SUCCESS) {
      form.resetFields();
    } else if (addRecordStatus === asyncStatuses.SUCCESS) {
      form.resetFields();
    }
  }, [editRecordStatus, addRecordStatus, form]);

  useEffect(() => {
    // console.log({ fetchARecord, currentRecordData });
    form.setFieldsValue(currentRecordData);
  }, [fetchRecordStatus, currentRecordData]);

  useEffect(() => {
    // console.log({ "useEffect for currentEditViewFormId && currentFormMode": { currentEditViewFormId, currentFormMode } });
    if (
      currentEditViewFormId &&
      (currentFormMode === FormModesEnum.EDIT ||
        currentFormMode === FormModesEnum.VIEW)
    ) {
      if (!currentEditViewFormId) {
        openNotification(
          "Something went wrong",
          "Please contact developers. Id was not provided while changing modes",
          "error"
        );
        return;
      } else {
        dispatch(fetchARecord({ apiUrl: apiUrl, id: currentEditViewFormId }));
      }
    }
  }, [currentEditViewFormId, currentFormMode, dispatch]);

  const getData = async () => {
    try {
      const response = await axios.get(`/order/orders/$id`);
      if (response?.data) {
        console.log(response);
      } else {
        console.log(response);
      }
    } catch (error) {}
  };

  const getUsers = async () => {
    const response = await fetchUserList();
    if (response) {
      setUsers(response);
    } else {
      console.log(response);
    }
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImage(file);
    }
  };

  const addNew = (val) => {
    // console.log(val);
    dispatch(
      addARecord({
        apiUrl: apiUrl,
        data: val,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    );
  };

  const edit = (val) => {
    // console.log(val);
    dispatch(
      editARecord({
        apiUrl,
        id: currentEditViewFormId,
        data: val,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    );
  };

  const onFinish = (val) => {
    delete val.confirm_password;
    val.username = val.email;
    val.image = image;
    if (currentFormMode === FormModesEnum.ADD) {
      addNew(val);
    } else if (currentFormMode === FormModesEnum.EDIT) {
      edit(val);
    } else {
      console.log("No suitable mode found");
    }
  };

  useEffect(() => {
    // getData();
    getUsers();
  }, []);

  return (
    <>
      {contextHolder}
      <Form
        layout="vertical"
        requiredMark={true}
        onFinish={onFinish}
        autoComplete="off"
        form={form}
        disabled={currentFormMode === FormModesEnum.VIEW}
      >
        {/* <Form.Item
          label="Type of project"
          name="project_type_data"
          rules={[
            { required: true, message: "Please select the type of business!" },
          ]}
        >
          <Select placeholder="Select type of business">
            {TYPE_OF_BUSINESS_CHOICES.map((choice) => (
              <Option key={choice.value} value={choice.value}>
                {choice.label}
              </Option>
            ))}
          </Select>
        </Form.Item> */}

        <Form.Item
          label="Quoted Amount"
          name="quoted_amount"
          rules={[
            { required: false, message: "Please input the quoted amount!" },
          ]}
        >
          <Input placeholder="Enter quoted amount" />
        </Form.Item>

        <Form.Item
          label="Current Step"
          name="current_step"
          rules={[
            { required: true, message: "Please input the current step!" },
          ]}
        >
          <Input placeholder="Enter current step" />
        </Form.Item>

        <Form.Item
          label="Service Type"
          name="service_type"
          rules={[
            { required: false, message: "Please input the service type!" },
          ]}
        >
          <Input placeholder="Enter service type" />
        </Form.Item>

        <Form.Item
          label="Floorplan"
          name="floorplan"
          rules={[{ required: true, message: "Please select floorplan!" }]}
        >
          <Select placeholder="Select floorplan">
            {FLOORPLAN_CHOICES.map((choice) => (
              <Option key={choice.value} value={choice.value}>
                {choice.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Requirement"
          name="requirement"
          rules={[{ required: true, message: "Please select requirement!" }]}
        >
          <Select placeholder="Select requirement">
            {REQUIREMENT_CHOICES.map((choice) => (
              <Option key={choice.value} value={choice.value}>
                {choice.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Requirement Type"
          name="requirement_type"
          rules={[
            { required: true, message: "Please select requirement type!" },
          ]}
        >
          <Select placeholder="Select requirement type">
            {REQUIREMENT_TYPE_CHOICES.map((choice) => (
              <Option key={choice.value} value={choice.value}>
                {choice.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Area"
          name="area"
          rules={[{ required: true, message: "Please input the area!" }]}
        >
          <InputNumber min={0} max={20000} step={1} placeholder="Enter area" />
        </Form.Item>
        {/* display inspiration and user data */}
        {/* <Form.Item
          label="Selected Rooms"
          name="selected_rooms"
          rules={[
            {
              required: true,
              message: "Please select rooms!",
            },
          ]}
        >
          <Select placeholder="Select roomss">
            {SELECTED_ROOMS_CHOICES.map((choice) => (
              <Option key={choice.value} value={choice.value}>
                {choice.label}
              </Option>
            ))}
          </Select>
        </Form.Item> */}
        <Form.Item
          label="User"
          name="user"
          rules={[
            {
              required: true,
              message: "Please select user!",
            },
          ]}
        >
          <Select placeholder="Select user">
            {users?.map((choice) => (
              <Option key={choice.id} value={choice.id}>
                {choice.email}
              </Option>
            ))}
          </Select>
        </Form.Item>

        {console.log(currentRecordData)}
        {currentFormMode === FormModesEnum.ADD ? (
          <Form.Item>
            {addRecordStatus === asyncStatuses.LOADING ? (
              <Button type="primary" htmlType="submit" disabled>
                <LoadingOutlined />
                Submitting
              </Button>
            ) : (
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            )}
          </Form.Item>
        ) : currentFormMode === FormModesEnum.EDIT ? (
          <Form.Item>
            {editRecordStatus === asyncStatuses.LOADING ? (
              <Button type="primary" htmlType="submit" disabled>
                <LoadingOutlined />
                Updating
              </Button>
            ) : (
              <Button type="primary" htmlType="submit">
                Update
              </Button>
            )}
          </Form.Item>
        ) : (
          <></>
        )}
      </Form>
    </>
  );
};
export default AddEditForm;
