import React, { useEffect, useState } from "react";
import { Button, Form, Input, notification, Select, InputNumber } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  addARecord,
  editARecord,
  fetchARecord,
} from "Redux/MasterReducer/crudSlices";
// import apiUrls from 'Utils/apiUrls';
import { FormModesEnum, asyncStatuses } from "Redux/enums";
import { LoadingOutlined } from "@ant-design/icons";
import { useParams } from "react-router";
const { Option } = Select;

const PAYMENT_CHOICES = [
  { value: "pending", label: "Pending" },
  { value: "paid", label: "Paid" },
];

const AddEditForm = ({ apiUrl }) => {
  const [notificationApi, contextHolder] = notification.useNotification();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [image, setImage] = useState(null);

  const openNotification = (message, description, type) => {
    notificationApi[type]({
      message: message,
      description: description,
      placement: "top",
    });
  };

  const currentFormMode = useSelector((store) => store.master?.currentFormMode);
  const currentEditViewFormId = useSelector(
    (store) => store.master?.currentEditViewFormId
  );
  const fetchRecordStatus = useSelector(
    (store) => store.master?.fetchRecordStatus
  );
  const editRecordStatus = useSelector(
    (store) => store.master?.editRecordStatus
  );
  const addRecordStatus = useSelector((store) => store.master?.addRecordStatus);
  const currentRecordData = useSelector(
    (store) => store.master?.currentRecordData
  );

  useEffect(() => {
    if (editRecordStatus === asyncStatuses.SUCCESS) {
      form.resetFields();
    } else if (addRecordStatus === asyncStatuses.SUCCESS) {
      form.resetFields();
    }
  }, [editRecordStatus, addRecordStatus, form]);

  useEffect(() => {
    // console.log({ fetchARecord, currentRecordData });
    form.setFieldsValue(currentRecordData);
  }, [fetchRecordStatus, currentRecordData]);

  useEffect(() => {
    // console.log({ "useEffect for currentEditViewFormId && currentFormMode": { currentEditViewFormId, currentFormMode } });
    if (
      currentEditViewFormId &&
      (currentFormMode === FormModesEnum.EDIT ||
        currentFormMode === FormModesEnum.VIEW)
    ) {
      if (!currentEditViewFormId) {
        openNotification(
          "Something went wrong",
          "Please contact developers. Id was not provided while changing modes",
          "error"
        );
        return;
      } else {
        dispatch(fetchARecord({ apiUrl: apiUrl, id: currentEditViewFormId }));
      }
    }
  }, [currentEditViewFormId, currentFormMode, dispatch]);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImage(file);
    }
  };

  const addNew = (val) => {
    // console.log(val);
    dispatch(
      addARecord({
        apiUrl: apiUrl,
        data: val,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    );
  };

  const edit = (val) => {
    // console.log(val);
    dispatch(
      editARecord({
        apiUrl,
        id: currentEditViewFormId,
        data: val,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    );
  };

  const onFinish = (val) => {
    delete val.confirm_password;
    val.username = val.email;
    val.image = image;
    if (currentFormMode === FormModesEnum.ADD) {
      addNew(val);
    } else if (currentFormMode === FormModesEnum.EDIT) {
      edit(val);
    } else {
      console.log("No suitable mode found");
    }
  };

  return (
    <>
      {contextHolder}
      <Form
        layout="vertical"
        requiredMark={true}
        onFinish={onFinish}
        autoComplete="off"
        form={form}
        disabled={currentFormMode === FormModesEnum.VIEW}
      >
        <Form.Item
          label="Percentage pay"
          name="percentage_pay"
          rules={[
            {
              required: true,
              // type: "number",
              message: "Please input a valid perentage pay!",
            },
          ]}
        >
          <Input placeholder="Enter perentage pay" />
        </Form.Item>
        <Form.Item
          label="Percent GST"
          name="percent_gst"
          rules={[
            {
              required: true,
              type: "number",
              message: "Please input a valid percent gst!",
            },
          ]}
        >
          <Input placeholder="Enter percent gst" />
        </Form.Item>
        <Form.Item
          label="Step"
          name="step"
          rules={[
            {
              required: true,
              type: "number",
              message: "Please input a valid step!",
            },
          ]}
        >
          <Input placeholder="Enter step" />
        </Form.Item>
        <Form.Item
          label="Step description"
          name="step description"
          // rules={[
          //   {
          //     required: true,
          //     message: "Please input a valid step description!",
          //   },
          // ]}
        >
          <Input placeholder="Enter step description" />
        </Form.Item>

        <Form.Item
          label="Payment Status"
          name="payment_status"
          initialValue=""
          rules={[{ required: true, message: "Please select payment status!" }]}
          style={{ width: 200 }}
        >
          <Select placeholder="Select payment status" onChange={() => {}}>
            {PAYMENT_CHOICES.map((choice) => (
              <Option key={choice.value} value={choice.value}>
                {choice.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
        {currentFormMode === FormModesEnum.ADD ? (
          <Form.Item>
            {addRecordStatus === asyncStatuses.LOADING ? (
              <Button type="primary" htmlType="submit" disabled>
                <LoadingOutlined />
                Submitting
              </Button>
            ) : (
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            )}
          </Form.Item>
        ) : currentFormMode === FormModesEnum.EDIT ? (
          <Form.Item>
            {editRecordStatus === asyncStatuses.LOADING ? (
              <Button type="primary" htmlType="submit" disabled>
                <LoadingOutlined />
                Updating
              </Button>
            ) : (
              <Button type="primary" htmlType="submit">
                Update
              </Button>
            )}
          </Form.Item>
        ) : (
          <></>
        )}
      </Form>
    </>
  );
};
export default AddEditForm;
