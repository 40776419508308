import { getCommonColumns } from "Components/CustomTable/Commons";
import CustomTable from "Components/CustomTable/CustomTable";
import AddEditForm from "Components/Forms/Architect/RoomDetails/AddEditForm";
import getPermittedColumns from "Utils/getPermittedColumns";
import { useSelector } from "react-redux";

const apiUrl = "/project/rooms";
const headerTitle = "Room Details";
const editPermission = "roomdetails:edit";
const deletePermission = "roomdetails:delete";
const addPermission = "roomdetails:add"
const columns = [
    {
        title: 'Project',
        dataIndex: ['project_data', 'project_name'],
        key: 'project',
    },
    {
        title: 'Room Type',
        dataIndex: ['room_type_data', 'title'],
        key: 'room_type',
    },
    ...getCommonColumns({ apiUrl, editPermission, deletePermission, created: true, updated: true, action: true })
]

const RoomDetailsPage = () => {

    const userPermissions = useSelector(store => store.auth?.user?.user_permissions);
    let _columns = getPermittedColumns(columns, userPermissions, editPermission, deletePermission);

    return <CustomTable
        columns={_columns}
        apiUrl={apiUrl}
        headerTitle={headerTitle}
        MyFormComponent={AddEditForm}
        addPermissionNames={[addPermission]}
    />;
}

export default RoomDetailsPage;