import { Button, Space, Popconfirm, Tooltip, message } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import {
  deleteARecord,
  setFormModeAsEdit,
} from "Redux/MasterReducer/crudSlices";
import moment from "moment-timezone";
import { useDispatch, useSelector } from "react-redux";
import AccessControl from "Router/Access/AccessControl";
import { useNavigate } from "react-router";

export const ACTION_COLUMN_KEY = "operation";

export const RenderActionButtons = ({
  text,
  row,
  index,
  apiUrl,
  editPermission,
  deletePermission,
  isOrderPage = false,
  isInvoicePage = false,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((store) => store.auth.user);
  const userPermissions = useSelector(
    (store) => store.auth?.user?.user_permissions
  );
  const [messageApi, contextHolder] = message.useMessage();

  const userHasEditPermission = userPermissions?.includes?.(editPermission);
  const userHasDeletePermission = userPermissions?.includes?.(deletePermission);

  const copyIdToClipboard = () => {
    navigator.clipboard.writeText(row.id);
    messageApi.success(`${row.id} copied to clipboard.`);
  };

  return (
    <Space direction="horizontal">
      {contextHolder}
      <Space direction="horizontal">
        {userHasEditPermission && isOrderPage && (
          <AccessControl
            userPermissions={user.user_permissions}
            allowedPermissions={[editPermission]}
            renderNoAccess={() => <></>}
          >
            <Button
              type="default"
              shape="default"
              icon={<EditOutlined size={18} />}
              size="small"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginLeft: 5,
              }}
              onClick={() => {
                navigate(`/order/${row.id}/update-invoices`);
              }}
            >
              Invoices
            </Button>
          </AccessControl>
        )}
        {userHasEditPermission && isInvoicePage && (
          <AccessControl
            userPermissions={user.user_permissions}
            allowedPermissions={[editPermission]}
            renderNoAccess={() => <></>}
          >
            <Button
              type="default"
              shape="default"
              icon={<EditOutlined size={18} />}
              size="small"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginLeft: 5,
              }}
              onClick={(event) => navigate(`/order/invoice/${row.id}`)}
            >
              Invoice Services
            </Button>
          </AccessControl>
        )}
        {userHasEditPermission && (
          <AccessControl
            userPermissions={user.user_permissions}
            allowedPermissions={[editPermission]}
            renderNoAccess={() => <></>}
          >
            <Button
              type="default"
              shape="circle"
              icon={<EditOutlined />}
              size="small"
              onClick={(event) => {
                // dispatch(setFormModeAsEdit({ id: row?.id }));
                dispatch(
                  setFormModeAsEdit({
                    id:
                      apiUrl.split("/")[3] === "services" ? row?.slug : row?.id,
                  })
                );
                event.stopPropagation();
              }}
            />
          </AccessControl>
        )}
        {userHasDeletePermission && (
          <AccessControl
            userPermissions={user.user_permissions}
            allowedPermissions={[deletePermission]}
            renderNoAccess={() => <></>}
          >
            <Popconfirm
              title="Delete"
              description="Are you sure to delete this entry?"
              onConfirm={(e) => {
                console.log(apiUrl);
                // dispatch(deleteARecord({ apiUrl: apiUrl, id: row?.id }));
                dispatch(
                  deleteARecord({
                    apiUrl: apiUrl,
                    id:
                      apiUrl.split("/")[3] === "services" ? row?.slug : row?.id,
                  })
                );
              }}
              onCancel={(e) => console.log(e)}
              icon={<QuestionCircleOutlined style={{ color: "red" }} />}
            >
              <Button
                type="default"
                color="red"
                shape="circle"
                danger
                icon={<DeleteOutlined />}
                size="small"
              />
            </Popconfirm>
          </AccessControl>
        )}
      </Space>
      <Tooltip title={`${row.id}l`} trigger="hover">
        <Button
          type="default"
          shape="default"
          icon={<CopyOutlined size={18} />}
          size="small"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginLeft: 5,
          }}
          onClick={copyIdToClipboard}
        >
          Copy ID
        </Button>
      </Tooltip>
    </Space>
  );
};

export const RenderCreated = ({ text, row, index }) => {
  // console.log({text, row});
  return (
    <Space direction="vertical" style={{ gap: 0 }}>
      <span>
        {row?.updated_by_data?.first_name} {row?.updated_by_data?.last_name}
      </span>
      <span>
        {row?.created_at
          ? moment(row.created_at).format("DD MMM yyyy, hh:mm a")
          : ""}
      </span>
    </Space>
  );
};

export const RenderUpdated = ({ text, row, index }) => {
  // console.log({text, row});
  return (
    <Space direction="vertical" style={{ gap: 0 }}>
      <span>
        {row?.updated_by_data?.first_name} {row?.updated_by_data?.last_name}
      </span>
      <span>
        {row?.updated_at
          ? moment(row.updated_at).format("DD MMM yyyy, hh:mm a")
          : ""}
      </span>
    </Space>
  );
};

export const getCommonColumns = ({
  apiUrl,
  editPermission,
  deletePermission,
  created,
  updated,
  action,
  isOrderPage,
  isInvoicePage,
}) => {
  let toReturn = [];

  if (created) {
    toReturn.push({
      title: "Created",
      dataIndex: "created_at",
      key: "created",
      render: (text, row, index) => {
        return <RenderCreated text={text} row={row} index={index} />;
      },
    });
  }

  if (updated) {
    toReturn.push({
      title: "Updated",
      dataIndex: "updated_at",
      key: "updated",
      render: (text, row, index) => {
        return <RenderUpdated text={text} row={row} index={index} />;
      },
    });
  }

  if (action) {
    toReturn.push({
      title: "Action",
      key: ACTION_COLUMN_KEY,
      fixed: "right",
      width: 200,
      render: (text, row, index) => {
        return (
          <RenderActionButtons
            text={text}
            row={row}
            index={index}
            apiUrl={apiUrl}
            editPermission={editPermission}
            deletePermission={deletePermission}
            isOrderPage={isOrderPage}
            isInvoicePage={isInvoicePage}
          />
        );
      },
    });
  }

  return toReturn;
};
