import { getCommonColumns } from "Components/CustomTable/Commons";
import CustomTable from "Components/CustomTable/CustomTable";
import AddEditForm from "Components/Forms/Master/ProductType/AddEditForm";
import getPermittedColumns from "Utils/getPermittedColumns";
import { useSelector } from "react-redux";

const apiUrl = "/product/product-types";
const headerTitle = "Product Types Master";
const editPermission = "producttypes:edit";
const deletePermission = "producttypes:delete";
const addPermission = "producttypes:add"
const columns = [
    {
        title: 'Type',
        dataIndex: 'title',
        key: 'title',
        // render: (text) => <a>{text}</a>,
    },
    ...getCommonColumns({ apiUrl, editPermission, deletePermission, created: true, updated: true, action: true })
]

const ProductTypePage = () => {

    const userPermissions = useSelector(store => store.auth?.user?.user_permissions);
    let _columns = getPermittedColumns(columns, userPermissions, editPermission, deletePermission);

    return <CustomTable
        columns={_columns}
        apiUrl={apiUrl}
        headerTitle={headerTitle}
        MyFormComponent={AddEditForm}
        addPermissionNames={[addPermission]}
    />;
}

export default ProductTypePage;