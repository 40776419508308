import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Input,
  notification,
  Select,
  InputNumber,
  Row,
  Col,
  Spin,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  addARecord,
  editARecord,
  fetchARecord,
} from "Redux/MasterReducer/crudSlices";
// import apiUrls from 'Utils/apiUrls';
import { FormModesEnum, asyncStatuses } from "Redux/enums";
import { LoadingOutlined } from "@ant-design/icons";
import axios from "axios";
import { useNavigate, useParams } from "react-router";
const { Option } = Select;

const PAYMENT_CHOICES = [
  { value: "pending", label: "Pending" },
  { value: "paid", label: "Paid" },
];

const UpdateInvoicesForm = ({ apiUrl }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [notificationApi, contextHolder] = notification.useNotification();
  const dispatch = useDispatch();
  const [invoicesData, setInvoicesData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState(
    Array(4)
      .fill()
      .map((_, index) => ({
        id: null,
        step: index + 1,
        percentage_pay: "0.00",
        percent_gst: "0.00",
        payment_status: "Pending",
        order: id,
      }))
  );
  const [image, setImage] = useState(null);

  const openNotification = (message, description, type) => {
    notificationApi[type]({
      message: message,
      description: description,
      placement: "top",
    });
  };

  // const currentFormMode = useSelector((store) => store.master?.currentFormMode);
  // const currentEditViewFormId = useSelector(
  //   (store) => store.master?.currentEditViewFormId
  // );
  // const fetchRecordStatus = useSelector(
  //   (store) => store.master?.fetchRecordStatus
  // );
  // const editRecordStatus = useSelector(
  //   (store) => store.master?.editRecordStatus
  // );
  // const addRecordStatus = useSelector((store) => store.master?.addRecordStatus);
  // const currentRecordData = useSelector(
  //   (store) => store.master?.currentRecordData
  // );

  // useEffect(() => {
  //   if (editRecordStatus === asyncStatuses.SUCCESS) {
  //     form.resetFields();
  //   } else if (addRecordStatus === asyncStatuses.SUCCESS) {
  //     form.resetFields();
  //   }
  // }, [editRecordStatus, addRecordStatus, form]);

  // useEffect(() => {
  //   // console.log({ fetchARecord, currentRecordData });
  //   form.setFieldsValue(currentRecordData);
  // }, [fetchRecordStatus, currentRecordData]);

  // useEffect(() => {
  //   // console.log({ "useEffect for currentEditViewFormId && currentFormMode": { currentEditViewFormId, currentFormMode } });
  //   if (
  //     currentEditViewFormId &&
  //     (currentFormMode === FormModesEnum.EDIT ||
  //       currentFormMode === FormModesEnum.VIEW)
  //   ) {
  //     if (!currentEditViewFormId) {
  //       openNotification(
  //         "Something went wrong",
  //         "Please contact developers. Id was not provided while changing modes",
  //         "error"
  //       );
  //       return;
  //     } else {
  //       dispatch(fetchARecord({ apiUrl: apiUrl, id: currentEditViewFormId }));
  //     }
  //   }
  // }, [currentEditViewFormId, currentFormMode, dispatch]);

  const fetchData = async () => {
    const response = await axios.get(`/order/orders/${id}`);
    if (response?.data) {
      setFormData(response?.data?.invoices);
    } else {
      openNotification(
        "Something went wrong",
        "Error while fetching invoices",
        "error"
      );
    }
  };

  const getData = async () => {
    const response = await axios.get(`/order/invoices/?order=${id}`);
    console.log(response);
    if (response?.data) {
      setInvoicesData(response?.data?.results);
    }
    if (response?.data?.results) {
      const initialFormData = formData.map((_, index) => {
        const data =
          response?.data?.results?.find((item) => item.step === index + 1) ||
          {};
        // console.log(data, index);
        return {
          id: data?.id,
          percentage_pay: data?.percentage_pay ?? 0,
          percent_gst: data?.percent_gst ?? 0,
          payment_status: data?.payment_status ?? "pending",
        };
      });
      setFormData(initialFormData);
    }
    setLoading(false);
  };

  const handleChange = (index, field, value) => {
    console.log(value);
    const updatedData = [...formData];
    updatedData[index] = { ...updatedData[index], [field]: value };
    // console.log(updatedData);
    setFormData(updatedData);
  };

  const onSubmit = async () => {
    try {
      console.log(formData);
      const response = await axios.post(
        "/order/invoices/update_invoices/",
        formData
      );
      if (response?.data) {
        // form.resetFields();
        navigate("/order/invoices/");
      } else {
        console.log("Failed to Submit");
        openNotification(
          "Something went wrong",
          "Failed to submit data",
          "error"
        );
      }
    } catch (error) {}
  };

  // useEffect(() => {
  //   const updatedFormData = formData.map((item) => {
  //     const correspondingFdata = fdata.find((f) => f.step === item.step);
  //     return correspondingFdata
  //       ? {
  //           ...item,
  //           percentage_pay: correspondingFdata.percentage_pay,
  //           percent_gst: correspondingFdata.percent_gst,
  //           payment_status: correspondingFdata.payment_status,
  //         }
  //       : item;
  //   });

  //   setFormData(updatedFormData);
  // }, []);

  useEffect(() => {
    getData();
  }, [id]);

  return (
    <>
      {contextHolder}
      {loading ? (
        <div
          style={{
            height: "80vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spin />
        </div>
      ) : (
        <div>
          <Row>
            {formData.map((data, index) => (
              <Col span={6}>
                <InvoiceForm
                  key={index}
                  index={index}
                  formData={data}
                  onChange={handleChange}
                />
              </Col>
            ))}
          </Row>
          {/* <div style={{ display: "flex", justifyContent: "center" }}> */}
          <div>
            <Button type="primary" onClick={onSubmit}>
              Update All
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default UpdateInvoicesForm;

const InvoiceForm = ({ index, formData, onChange }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    // console.log(formData);
    form.setFieldsValue(formData);
  }, [formData]);

  return (
    <Form
      form={form}
      layout="vertical"
      // defaultValue={formData}
      // style={{ maxHeight: "80vh", overflowY: "scroll" }}
    >
      <p>
        <b>
          {index === 0
            ? "1. Consultation"
            : index === 1
            ? "2: Requirement Gathering and Site Measurement"
            : index === 2
            ? "3: Creating Customized Drawings and 3D Package"
            : "4: Site Execution"}
        </b>
      </p>
      <Form.Item
        label="Percentage Pay"
        name="percentage_pay"
        initialValue={formData.percentage_pay}
        rules={[{ required: true, message: "Please input percentage pay!" }]}
      >
        <Input
          min={0}
          max={100}
          step={0.1}
          style={{ width: 200 }}
          placeholder="Enter percentage pay"
          onChange={(e) => onChange(index, "percentage_pay", e.target.value)}
        />
      </Form.Item>
      <Form.Item
        label="Percentage GST"
        name="percent_gst"
        // initialValue={formData.percent_gst}
        rules={[{ required: true, message: "Please input percentage GST!" }]}
      >
        <Input
          min={0}
          max={100}
          step={0.1}
          style={{ width: 200 }}
          placeholder="Enter percentage GST"
          onChange={(e) => onChange(index, "percent_gst", e.target.value)}
        />
      </Form.Item>
      <Form.Item
        label="Payment Status"
        name="payment_status"
        // initialValue={formData.payment_status}
        rules={[{ required: true, message: "Please select payment status!" }]}
        style={{ width: 200 }}
      >
        <Select
          placeholder="Select payment status"
          onChange={(e) => onChange(index, "payment_status", e.target.value)}
        >
          {PAYMENT_CHOICES.map((choice) => (
            <Option key={choice.value} value={choice.value}>
              {choice.label}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </Form>
  );
};
