import React, { useEffect, useState } from 'react';
import { Button, Form, Input, notification, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { addARecord, editARecord, fetchARecord } from 'Redux/MasterReducer/crudSlices';
// import apiUrls from 'Utils/apiUrls';
import { FormModesEnum, asyncStatuses } from 'Redux/enums';
import { LoadingOutlined } from "@ant-design/icons"
import axios from 'axios';
const { Option } = Select;

const AddEditForm = ({ apiUrl }) => {
  const [notificationApi, contextHolder] = notification.useNotification();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [properties, setProperties] = useState([]);
  const fetchProperties = async () => {
    try {
      const response = await axios.get("/property/properties/");
      console.log(response);
      setProperties(response.data.results);
    } catch (err) {
      console.error(err);
      throw new Error("Error while fetching countries list");
    }
  };

  const openNotification = (message, description, type) => {
    notificationApi[type]({
      message: message,
      description: description,
      placement: "top"
    });
  };

  const currentFormMode = useSelector(store => store.master?.currentFormMode);
  const currentEditViewFormId = useSelector(store => store.master?.currentEditViewFormId);
  const fetchRecordStatus = useSelector(store => store.master?.fetchRecordStatus);
  const editRecordStatus = useSelector(store => store.master?.editRecordStatus);
  const addRecordStatus = useSelector(store => store.master?.addRecordStatus);
  const currentRecordData = useSelector(store => store.master?.currentRecordData);

  useEffect(() => {
    fetchProperties()
  }, [])

  useEffect(() => {
    if (editRecordStatus === asyncStatuses.SUCCESS) {
      form.resetFields();
    } else if (addRecordStatus === asyncStatuses.SUCCESS) {
      form.resetFields();
    }
  }, [editRecordStatus, addRecordStatus, form]);

  useEffect(() => {
    // console.log({ fetchARecord, currentRecordData });
    form.setFieldsValue(currentRecordData);
  }, [fetchRecordStatus, currentRecordData]);

  useEffect(() => {
    // console.log({ "useEffect for currentEditViewFormId && currentFormMode": { currentEditViewFormId, currentFormMode } });
    if (currentEditViewFormId && (currentFormMode === FormModesEnum.EDIT || currentFormMode === FormModesEnum.VIEW)) {
      if (!currentEditViewFormId) {
        openNotification("Something went wrong", "Please contact developers. Id was not provided while changing modes", "error");
        return;
      } else {
        dispatch(fetchARecord({ apiUrl: apiUrl, id: currentEditViewFormId }));
      }
    }
  }, [currentEditViewFormId, currentFormMode, dispatch]);

  const addNew = (val) => {
    // console.log(val);
    dispatch(addARecord({ apiUrl: apiUrl, data: val }));
  }

  const edit = (val) => {
    // console.log(val);
    dispatch(editARecord({ apiUrl, id: currentEditViewFormId, data: val }));
  }

  const onFinish = (val) => {
    delete val.confirm_password;
    val.username = val.email;
    if (currentFormMode === FormModesEnum.ADD) {
      addNew(val);
    } else if (currentFormMode === FormModesEnum.EDIT) {
      edit(val);
    } else {
      console.log("No suitable mode found");
    }
  }
  return (
    <>
      {contextHolder}
      <Form layout="vertical"
        requiredMark={true}
        onFinish={onFinish}
        autoComplete="off"
        form={form}
        disabled={currentFormMode === FormModesEnum.VIEW}
      >
      <Form.Item
        name="property"
        label="Property"
        rules={[{ required: true, message: 'Please select a property!' }]}
      >
        <Select placeholder="Select a property">
          {properties.map(property => (
            <Option key={property.id} value={property.id}>{property.property_name}</Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        name="block"
        label="Block"
        rules={[{ required: true, message: 'Please enter the block!' }]}
      >
        <Input placeholder="Enter block" />
      </Form.Item>
        {
          currentFormMode === FormModesEnum.ADD ? <Form.Item>
            {
              addRecordStatus === asyncStatuses.LOADING ?
                <Button type="primary" htmlType="submit" disabled>
                  <LoadingOutlined />
                  Submitting
                </Button> :
                <Button type="primary" htmlType="submit" >
                  Submit
                </Button>
            }
          </Form.Item> : currentFormMode === FormModesEnum.EDIT ? <Form.Item>
            {
              editRecordStatus === asyncStatuses.LOADING ?
                <Button type="primary" htmlType="submit" disabled>
                  <LoadingOutlined />
                  Updating
                </Button> :
                <Button type="primary" htmlType="submit" >
                  Update
                </Button>
            }
          </Form.Item> : <></>
        }

      </Form >
    </>
  );
};
export default AddEditForm;