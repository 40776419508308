import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Input, notification, Select, InputNumber } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  addARecord,
  editARecord,
  fetchARecord,
} from "Redux/MasterReducer/crudSlices";
// import apiUrls from 'Utils/apiUrls';
import { FormModesEnum, asyncStatuses } from "Redux/enums";
import { LoadingOutlined } from "@ant-design/icons";
import axios from "axios";
import { CKEditor } from "ckeditor4-react";
import { useParams } from "react-router";
const { Option } = Select;

const editorConfig = {
  extraPlugins: ["tableresize", "uploadimage", "createpdf"],
  removePlugins: [
    "exportpdf",
    "forms",
    "smiley",
    "language",
    "iframe",
    "about",
  ],
  // filebrowserBrowseUrl: 'http://localhost:4000/media/24b358cf-8e56-4b03-9843-29fabf589b8d/template/home-advt_jzx7KBU.jpeg',
  filebrowserImageUploadUrl:
    process.env.REACT_APP_BASE_URL + `/template/upload_template_image/`,
  fileTools_requestHeaders: axios.defaults.headers.common,
  uploadUrl:
    process.env.REACT_APP_BASE_URL + `/template/upload_template_image/`,
  contentsCss: [
    // "https://cdn.jsdelivr.net/npm/bootstrap@4.3.1/dist/css/bootstrap.min.css",
    process.env.REACT_APP_BASE_URL +
      "/staticfiles/ckeditor/4.19.0/full-all/document-style.css",
  ],
  bodyClass: "document-editor",
  // height: 900, // width: 1000,
  height: "5cm",
  width: "13.5cm",
  // width: "21cm",
  allowedContent: true,
  // extraPlugins: [ // 'timestamp' // ] // exportPdf_options: { // header_html: '<div class="styled">This is the Header</div>', // footer_html: '<div class="styled-counter"><span class="date></span></div>', // header_and_footer_css: '.styled { font-weight: bold; padding: 10px; display: flex; color: red; } .styled-counter {font-size: 10px; color: red; }', // margin_top: '2cm', // margin_bottom: '10cm', // } // exportPdf_options: { // format: "A3" // }
  exportPdf_options: {
    header_html: '<div class="styled">Header content</div>',
    footer_html: '<div class="styled"><span class="pageNumber"></span></div>',
    header_and_footer_css:
      ".styled { font-weight: bold; padding: 10px; text-align: center; color: red; }",
    margin_left: "1cm",
    margin_right: "2cm",
    format: "A5",
    page_orientation: "landscape",
  },
  protectedSource: [/{%[\s\S]?%}+/g, /{{[\s\S]?}}+/g],
  // formatOutput: false,
  // formatSource: false
};

const AddEditForm = ({}) => {
  const apiUrl = "/order/invoice-services/";
  const dispatch = useDispatch();
  const { id } = useParams();
  const [notificationApi, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();

  const [image, setImage] = useState(null);
  const [editorData, setEditorData] = useState(null);

  const openNotification = (message, description, type) => {
    notificationApi[type]({
      message: message,
      description: description,
      placement: "top",
    });
  };

  const currentFormMode = useSelector((store) => store.master?.currentFormMode);
  const currentEditViewFormId = useSelector(
    (store) => store.master?.currentEditViewFormId
  );
  const fetchRecordStatus = useSelector(
    (store) => store.master?.fetchRecordStatus
  );
  const editRecordStatus = useSelector(
    (store) => store.master?.editRecordStatus
  );
  const addRecordStatus = useSelector((store) => store.master?.addRecordStatus);
  const currentRecordData = useSelector(
    (store) => store.master?.currentRecordData
  );

  useEffect(() => {
    if (editRecordStatus === asyncStatuses.SUCCESS) {
      form.resetFields();
    } else if (addRecordStatus === asyncStatuses.SUCCESS) {
      form.resetFields();
    }
  }, [editRecordStatus, addRecordStatus, form]);

  useEffect(() => {
    console.log(currentRecordData);
    form.setFieldsValue({
      service: currentRecordData.service,
      amount: currentRecordData.amount,
      description: currentRecordData.description,
      invoice: id,
    });
    console.log(form.getFieldsValue());
    console.log(currentRecordData.description);
    console.log(form.getFieldValue("description"));
    setEditorData(form.getFieldValue("description"));
    // console.log({ fetchARecord, currentRecordData });
  }, [fetchRecordStatus, currentRecordData]);

  useEffect(() => {
    // console.log({ "useEffect for currentEditViewFormId && currentFormMode": { currentEditViewFormId, currentFormMode } });
    if (
      currentEditViewFormId &&
      (currentFormMode === FormModesEnum.EDIT ||
        currentFormMode === FormModesEnum.VIEW)
    ) {
      if (!currentEditViewFormId) {
        openNotification(
          "Something went wrong",
          "Please contact developers. Id was not provided while changing modes",
          "error"
        );
        return;
      } else {
        dispatch(fetchARecord({ apiUrl: apiUrl, id: currentEditViewFormId }));
      }
    }
  }, [currentEditViewFormId, currentFormMode, dispatch]);

  const addNew = (val) => {
    // console.log(val);
    dispatch(
      addARecord({
        apiUrl: apiUrl,
        data: val,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    );
  };

  const edit = (val) => {
    // console.log(val);
    dispatch(
      editARecord({
        apiUrl,
        id: currentEditViewFormId,
        data: val,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    );
  };

  const onFinish = (val) => {
    val.description = editorData;
    val.invoice = id;
    console.log(val);
    if (currentFormMode === FormModesEnum.ADD) {
      addNew(val);
    } else if (currentFormMode === FormModesEnum.EDIT) {
      edit(val);
    } else {
      console.log("No suitable mode found");
    }
  };

  return (
    <>
      {contextHolder}
      <Form
        layout="vertical"
        form={form}
        // initialValues={formData}
        // defaultValue={formData}
        onFinish={onFinish}
        disabled={currentFormMode === FormModesEnum.VIEW}
      >
        <Form.Item
          label="Service"
          name="service"
          rules={[{ required: true, message: "Please input the service!" }]}
        >
          <Input
            placeholder="Enter service"
            // onChange={(e) => onChange(index, "service", e.target.value)}
          />
        </Form.Item>
        <Form.Item
          label="Amount"
          name="amount"
          rules={[{ required: true, message: "Please input the amount!" }]}
        >
          <Input
            placeholder="Enter amount"
            // onChange={(e) => onChange(index, "amount", e.target.value)}
          />
        </Form.Item>
        {/* <Form.Item
          label="Description"
          name="description"
          rules={[
            { required: false, message: "Please input the description!" },
          ]}
        >
          <Input
            placeholder="Enter description"
            // onChange={(e) => onChange(index, "description", e.target.value)}
          />
        </Form.Item> */}
        <Form.Item label="Description" name="description">
          {editorData != null && (
            <CKEditor
              config={editorConfig}
              editorUrl={
                process.env.REACT_APP_BASE_URL +
                "/staticfiles/ckeditor/4.19.0/full-all/ckeditor.js"
              }
              name="description"
              initData={editorData}
              onChange={(e) => setEditorData(e.editor.getData())}
              // onChange={(e) => onChange(index, "description", e.editor.getData())}
            />
          )}
        </Form.Item>
        {/* <Button type="primary" htmlType="submit">
          <LoadingOutlined />
          Submit
        </Button> */}
        {currentFormMode === FormModesEnum.ADD ? (
          <Form.Item>
            {addRecordStatus === asyncStatuses.LOADING ? (
              <Button type="primary" htmlType="submit" disabled>
                <LoadingOutlined />
                Submitting
              </Button>
            ) : (
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            )}
          </Form.Item>
        ) : currentFormMode === FormModesEnum.EDIT ? (
          <Form.Item>
            {editRecordStatus === asyncStatuses.LOADING ? (
              <Button type="primary" htmlType="submit" disabled>
                <LoadingOutlined />
                Updating
              </Button>
            ) : (
              <Button type="primary" htmlType="submit">
                Update
              </Button>
            )}
          </Form.Item>
        ) : (
          <></>
        )}
      </Form>
    </>
  );
};
export default AddEditForm;
