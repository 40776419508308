import React, { useEffect, useState } from "react";
import { Button, Form, Input, notification, Select, InputNumber } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  addARecord,
  editARecord,
  fetchARecord,
} from "Redux/MasterReducer/crudSlices";
// import apiUrls from 'Utils/apiUrls';
import { FormModesEnum, asyncStatuses } from "Redux/enums";
import { LoadingOutlined } from "@ant-design/icons";
import axios from "axios";
const {Option} = Select;

const AddEditForm = ({ apiUrl }) => {
  const [notificationApi, contextHolder] = notification.useNotification();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [brandOptions, setBrandOptions] = useState([]);
  const [productTypeOptions, setProductTypeOptions] = useState([]);
  const [productImages, setProductImages] = useState([])

  const fetchBrands = async () => {
    try {
      const response = await axios.get("/user/rest/brand-info/");
      console.log(response);
      setBrandOptions(response.data.results);
    } catch (err) {
      console.error(err);
      throw new Error("Error while fetching countries list");
    }
  };

  const fetchProductTypes = async () => {
    try {
      const response = await axios.get("/product/product-types/");
      console.log(response);
      setProductTypeOptions(response.data.results);
    } catch (err) {
      console.error(err);
      throw new Error("Error while fetching countries list");
    }
  };

  const openNotification = (message, description, type) => {
    notificationApi[type]({
      message: message,
      description: description,
      placement: "top",
    });
  };

  const currentFormMode = useSelector((store) => store.master?.currentFormMode);
  const currentEditViewFormId = useSelector(
    (store) => store.master?.currentEditViewFormId
  );
  const fetchRecordStatus = useSelector(
    (store) => store.master?.fetchRecordStatus
  );
  const editRecordStatus = useSelector(
    (store) => store.master?.editRecordStatus
  );
  const addRecordStatus = useSelector((store) => store.master?.addRecordStatus);
  const currentRecordData = useSelector(
    (store) => store.master?.currentRecordData
  );

  useEffect(() => {
    fetchBrands();
  }, []);

  useEffect(() => {
    fetchProductTypes();
  }, []);

  useEffect(() => {
    if (editRecordStatus === asyncStatuses.SUCCESS) {
      form.resetFields();
    } else if (addRecordStatus === asyncStatuses.SUCCESS) {
      form.resetFields();
    }
  }, [editRecordStatus, addRecordStatus, form]);

  useEffect(() => {
    // console.log({ fetchARecord, currentRecordData });
    form.setFieldsValue(currentRecordData);
  }, [fetchRecordStatus, currentRecordData]);

  useEffect(() => {
    // console.log({ "useEffect for currentEditViewFormId && currentFormMode": { currentEditViewFormId, currentFormMode } });
    if (
      currentEditViewFormId &&
      (currentFormMode === FormModesEnum.EDIT ||
        currentFormMode === FormModesEnum.VIEW)
    ) {
      if (!currentEditViewFormId) {
        openNotification(
          "Something went wrong",
          "Please contact developers. Id was not provided while changing modes",
          "error"
        );
        return;
      } else {
        dispatch(fetchARecord({ apiUrl: apiUrl, id: currentEditViewFormId }));
      }
    }
  }, [currentEditViewFormId, currentFormMode, dispatch]);

  const handleFileChange = (e) => {
    setProductImages(Array.from(e.target.files));
  };
  
  const addNew = (val) => {
    // console.log(val);
    dispatch(addARecord({ apiUrl: apiUrl, data: val, headers: {
      "Content-Type": "multipart/form-data",
    },}));
  };

  const edit = (val) => {
    // console.log(val);
    dispatch(editARecord({ apiUrl, id: currentEditViewFormId, data: val }));
  };

  const onFinish = (val) => {
    delete val.confirm_password;
    val.username = val.email;
    productImages.forEach((file, index) => {
      val[`file-${index}`] = file
    });
    if (currentFormMode === FormModesEnum.ADD) {
      addNew(val);
    } else if (currentFormMode === FormModesEnum.EDIT) {
      edit(val);
    } else {
      console.log("No suitable mode found");
    }
  };
  return (
    <>
      {contextHolder}
      <Form
        layout="vertical"
        requiredMark={true}
        onFinish={onFinish}
        autoComplete="off"
        form={form}
        disabled={currentFormMode === FormModesEnum.VIEW}
      >
        <Form.Item
          label="Brand"
          name="brand"
          rules={[{ required: true, message: "Please select a brand!" }]}
        >
          <Select placeholder="Select a brand">
            {brandOptions?.map((brand) => (
              <Option key={brand.id} value={brand.id}>
                {brand.company_name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Title"
          name="title"
          rules={[{ required: true, message: "Please input the title!" }]}
        >
          <Input placeholder="Enter product title" />
        </Form.Item>

        <Form.Item
          label="Product Type"
          name="product_type"
          rules={[{ required: true, message: "Please select a product type!" }]}
        >
          <Select placeholder="Select a product type">
            {productTypeOptions?.map((type) => (
              <Option key={type.validue} value={type.id}>
                {type.title}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Description"
          name="description"
          rules={[{ required: true, message: "Please input the description!" }]}
        >
          <Input.TextArea placeholder="Enter product description" />
        </Form.Item>

        <Form.Item
         label="Cost"
         name="cost"
         rules={[{ required: true, message: "Please input the cost!" }]}
        >
          <Input placeholder="Enter product cost" />
        </Form.Item>

        <Form.Item
          label="Size"
          name="size"
          rules={[{ required: true, message: "Please input the size!" }]}
        >
          <Input placeholder="Enter product size" />
        </Form.Item>
        <Form.Item 
          label="Images"
          rules={[{ required: true, message: "Please upload an image!" }]}>
          <input type="file" id="imageUpload" multiple onChange={handleFileChange} />
        </Form.Item>
        {currentFormMode === FormModesEnum.ADD ? (
          <Form.Item>
            {addRecordStatus === asyncStatuses.LOADING ? (
              <Button type="primary" htmlType="submit" disabled>
                <LoadingOutlined />
                Submitting
              </Button>
            ) : (
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            )}
          </Form.Item>
        ) : currentFormMode === FormModesEnum.EDIT ? (
          <Form.Item>
            {editRecordStatus === asyncStatuses.LOADING ? (
              <Button type="primary" htmlType="submit" disabled>
                <LoadingOutlined />
                Updating
              </Button>
            ) : (
              <Button type="primary" htmlType="submit">
                Update
              </Button>
            )}
          </Form.Item>
        ) : (
          <></>
        )}
      </Form>
    </>
  );
};
export default AddEditForm;
