import React, { useEffect, useState } from 'react';
import { Button, Form, Input, notification, Select, InputNumber } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { addARecord, editARecord, fetchARecord } from 'Redux/MasterReducer/crudSlices';
// import apiUrls from 'Utils/apiUrls';
import { FormModesEnum, asyncStatuses } from 'Redux/enums';
import { LoadingOutlined } from "@ant-design/icons"
import axios from 'axios';
const { Option } = Select;

const AddEditForm = ({ apiUrl }) => {
  const [notificationApi, contextHolder] = notification.useNotification();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [builders, setBuilders] = useState([]);
  const [projectTypes, setProjectTypes] = useState([]);


  const fetchBuilders = async () => {
    try {
      const response = await axios.get("/user/rest/builders/");
      console.log(response);
      setBuilders(response.data.results);
    } catch (err) {
      console.error(err);
      throw new Error("Error while fetching countries list");
    }
  };

  const fetchProjectTypes = async () => {
    try {
      const response = await axios.get("/project/project-types/");
      console.log(response);
      setProjectTypes(response.data.results);
    } catch (err) {
      console.error(err);
      throw new Error("Error while fetching countries list");
    }
  };

  const openNotification = (message, description, type) => {
    notificationApi[type]({
      message: message,
      description: description,
      placement: "top"
    });
  };

  const currentFormMode = useSelector(store => store.master?.currentFormMode);
  const currentEditViewFormId = useSelector(store => store.master?.currentEditViewFormId);
  const fetchRecordStatus = useSelector(store => store.master?.fetchRecordStatus);
  const editRecordStatus = useSelector(store => store.master?.editRecordStatus);
  const addRecordStatus = useSelector(store => store.master?.addRecordStatus);
  const currentRecordData = useSelector(store => store.master?.currentRecordData);

  useEffect(() => {
    fetchBuilders()
    fetchProjectTypes()
  }, [])

  useEffect(() => {
    if (editRecordStatus === asyncStatuses.SUCCESS) {
      form.resetFields();
    } else if (addRecordStatus === asyncStatuses.SUCCESS) {
      form.resetFields();
    }
  }, [editRecordStatus, addRecordStatus, form]);

  useEffect(() => {
    // console.log({ fetchARecord, currentRecordData });
    form.setFieldsValue(currentRecordData);
  }, [fetchRecordStatus, currentRecordData]);

  useEffect(() => {
    // console.log({ "useEffect for currentEditViewFormId && currentFormMode": { currentEditViewFormId, currentFormMode } });
    if (currentEditViewFormId && (currentFormMode === FormModesEnum.EDIT || currentFormMode === FormModesEnum.VIEW)) {
      if (!currentEditViewFormId) {
        openNotification("Something went wrong", "Please contact developers. Id was not provided while changing modes", "error");
        return;
      } else {
        dispatch(fetchARecord({ apiUrl: apiUrl, id: currentEditViewFormId }));
      }
    }
  }, [currentEditViewFormId, currentFormMode, dispatch]);

  const addNew = (val) => {
    // console.log(val);
    dispatch(addARecord({ apiUrl: apiUrl, data: val }));
  }

  const edit = (val) => {
    // console.log(val);
    dispatch(editARecord({ apiUrl, id: currentEditViewFormId, data: val }));
  }

  const onFinish = (val) => {
    delete val.confirm_password;
    val.username = val.email;
    if (currentFormMode === FormModesEnum.ADD) {
      addNew(val);
    } else if (currentFormMode === FormModesEnum.EDIT) {
      edit(val);
    } else {
      console.log("No suitable mode found");
    }
  }
  return (
    <>
      {contextHolder}
      <Form layout="vertical"
        requiredMark={true}
        onFinish={onFinish}
        autoComplete="off"
        form={form}
        disabled={currentFormMode === FormModesEnum.VIEW}
      >
        <Form.Item
          name="builder"
          label="Builder"
          rules={[{ required: true, message: 'Please select a builder!' }]}
        >
          <Select placeholder="Select a builder">
            {builders.map(builder => (
              <Option key={builder.id} value={builder.id}>{builder.company_name}</Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="property_name"
          label="Property Name"
          rules={[{ required: true, message: 'Please enter the property name!' }]}
        >
          <Input placeholder="Enter property name" />
        </Form.Item>

        <Form.Item
          name="flats"
          label="Number of Flats"
          rules={[{ required: true, message: 'Please enter the number of flats!' }]}
        >
          <InputNumber min={0} placeholder="Enter number of flats" />
        </Form.Item>

        <Form.Item
          name="property_year"
          label="Property Year"
          rules={[{ required: true, message: 'Please enter the year the property was made!' }]}
        >
          <InputNumber min={0} placeholder="Enter built year of property" />
        </Form.Item>

        <Form.Item
          name="location"
          label="Location"
          rules={[{ required: true, message: 'Please enter the location!' }]}
        >
          <Input placeholder="Enter location" />
        </Form.Item>

        <Form.Item
          name="project_type"
          label="Project Type"
          rules={[{ required: true, message: 'Please select a project type!' }]}
        >
          <Select placeholder="Select a project type">
            {projectTypes.map(type => (
              <Option key={type.id} value={type.id}>{type.title}</Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="construction_status"
          label="Construction Status"
          rules={[{ required: true, message: 'Please select the construction status!' }]}
        >
          <Select placeholder="Select a construction status">
            <Option value="UNDER_CONSTRUCTION">Under Construction</Option>
            <Option value="COMPLETED">Completed</Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="new_property"
          label="Is New Property?"
        >
          <Select placeholder="Select">
            <Option value={true}>True</Option>
            <Option value={false}>False</Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="recommended"
          label="Is Recommended?"
        >
          <Select placeholder="Select">
            <Option value={true}>True</Option>
            <Option value={false}>False</Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="status"
          label="Approval Status"
          rules={[{ required: true, message: 'Please select the approval status!' }]}
        >
          <Select placeholder="Select a status">
            <Option value="UNDER_APPROVAL">Under Approval</Option>
            <Option value="APPROVED">Approved</Option>
            <Option value="REJECTED">Rejected</Option>
          </Select>
        </Form.Item>

        {
          currentFormMode === FormModesEnum.ADD ? <Form.Item>
            {
              addRecordStatus === asyncStatuses.LOADING ?
                <Button type="primary" htmlType="submit" disabled>
                  <LoadingOutlined />
                  Submitting
                </Button> :
                <Button type="primary" htmlType="submit" >
                  Submit
                </Button>
            }
          </Form.Item> : currentFormMode === FormModesEnum.EDIT ? <Form.Item>
            {
              editRecordStatus === asyncStatuses.LOADING ?
                <Button type="primary" htmlType="submit" disabled>
                  <LoadingOutlined />
                  Updating
                </Button> :
                <Button type="primary" htmlType="submit" >
                  Update
                </Button>
            }
          </Form.Item> : <></>
        }

      </Form >
    </>
  );
};
export default AddEditForm;