import { Routes, Route, Navigate } from "react-router-dom";
import LoginPage from "Pages/Login/LoginPage";

const AuthRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<LoginPage />} />
      <Route path="*" element={<Navigate to={"/"} />} />
    </Routes>
  );
};

export default AuthRoutes;
