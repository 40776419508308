

import { Flex } from 'antd'
import React from 'react'

const HomePage = () => {
  return (
    <Flex vertical align='center' justify='center' style={{height: '100%'}}>
        <h1 >Interiokeys Admin Portal</h1>
        <p>Please, use navigation menus on left to navigate</p>
    </Flex>
  )
}

export default HomePage