import { getCommonColumns } from "Components/CustomTable/Commons";
import CustomTable from "Components/CustomTable/CustomTable";
import AddEditForm from "Components/Forms/Builder/PropertyBlocks/AddEditForm";
import getPermittedColumns from "Utils/getPermittedColumns";
import { useSelector } from "react-redux";

const apiUrl = "/property/property-resources";
const headerTitle = "Property Blocks";
const editPermission = "properties:edit";
const deletePermission = "properties:delete";
const addPermission = "properties:add"
const columns = [
    {
        title: 'Property',
        dataIndex: ['property_data', 'property_name'],
        key: 'property',
    },
    {
        title: 'Block',
        dataIndex: 'block',
        key: 'block',
    },
   
    ...getCommonColumns({ apiUrl, editPermission, deletePermission, created: true, updated: true, action: true })
]

const PropertyBlocksPage = () => {

    const userPermissions = useSelector(store => store.auth?.user?.user_permissions);
    let _columns = getPermittedColumns(columns, userPermissions, editPermission, deletePermission);

    return <CustomTable
        columns={_columns}
        apiUrl={apiUrl}
        headerTitle={headerTitle}
        MyFormComponent={AddEditForm}
        addPermissionNames={[addPermission]}
    />;
}

export default PropertyBlocksPage;