import { getCommonColumns } from "Components/CustomTable/Commons";
import CustomTable from "Components/CustomTable/CustomTable";
import AddEditForm from "Components/Forms/Builder/Properties/AddEditForm";
import getPermittedColumns from "Utils/getPermittedColumns";
import { useSelector } from "react-redux";

const apiUrl = "/property/properties";
const headerTitle = "Property";
const editPermission = "properties:edit";
const deletePermission = "properties:delete";
const addPermission = "properties:add"
const columns = [
    {
        title: 'Property Name',
        dataIndex: 'property_name',
        key: 'property_name',
    },
    {
        title: 'Builder',
        dataIndex: ['builder_data', 'company_name'],
        key: 'builder',
    },
    {
        title: 'Approval Status',
        dataIndex: 'status',
        key: 'status',
    },
    ...getCommonColumns({ apiUrl, editPermission, deletePermission, created: true, updated: true, action: true })
]

const PropertiesPage = () => {

    const userPermissions = useSelector(store => store.auth?.user?.user_permissions);
    let _columns = getPermittedColumns(columns, userPermissions, editPermission, deletePermission);

    return <CustomTable
        columns={_columns}
        apiUrl={apiUrl}
        headerTitle={headerTitle}
        MyFormComponent={AddEditForm}
        addPermissionNames={[addPermission]}
    />;
}

export default PropertiesPage;