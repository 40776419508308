import React, { useEffect, useState } from "react";
import { Button, Form, Input, notification, Select, InputNumber } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  addARecord,
  editARecord,
  fetchARecord,
} from "Redux/MasterReducer/crudSlices";
// import apiUrls from 'Utils/apiUrls';
import { FormModesEnum, asyncStatuses } from "Redux/enums";
import { LoadingOutlined } from "@ant-design/icons";
const { Option } = Select;
const TYPE_OF_BUSINESS_CHOICES = [
  // { value: 'ADH', label: 'Adhesives' },
  // { value: 'WF', label: 'Wood and Vinyl Flooring' },
  // { value: 'TEW', label: 'Natural, Teak, Engineered Wood' },
  // { value: 'HCP', label: 'HPL, Compacts, Panels, Wall Cladding, etc' },
  { value: "laminates", label: "Laminates" },
  { value: "plywoods", label: "Plywoods" },
  { value: "outdoor_furniture", label: "Outdoor Furniture" },
  { value: "bathroom_fitting", label: "Bathroom Fitting" },
  { value: "veneers", label: "Veneers" },
  { value: "decorative", label: "Decoratives" },
  { value: "wallpapers", label: "Wallpapers" },
  { value: "adhesives", label: "Adhesives" },
  { value: "wooden_flooring", label: "Wooden Flooring" },
  { value: "ceramic_tiles", label: "Ceramic Files" },
  { value: "marbles", label: "Marbles" },
  { value: "lightitng_fixtures", label: "Lighting Fixtures" },
  { value: "kitchen_modules", label: "Kitchen Modules" },
  { value: "hardwares", label: "Hardwares" },
];

const AddEditForm = ({ apiUrl }) => {
  const [notificationApi, contextHolder] = notification.useNotification();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [image, setImage] = useState(null);

  const openNotification = (message, description, type) => {
    notificationApi[type]({
      message: message,
      description: description,
      placement: "top",
    });
  };

  const currentFormMode = useSelector((store) => store.master?.currentFormMode);
  const currentEditViewFormId = useSelector(
    (store) => store.master?.currentEditViewFormId
  );
  const fetchRecordStatus = useSelector(
    (store) => store.master?.fetchRecordStatus
  );
  const editRecordStatus = useSelector(
    (store) => store.master?.editRecordStatus
  );
  const addRecordStatus = useSelector((store) => store.master?.addRecordStatus);
  const currentRecordData = useSelector(
    (store) => store.master?.currentRecordData
  );

  useEffect(() => {
    if (editRecordStatus === asyncStatuses.SUCCESS) {
      form.resetFields();
    } else if (addRecordStatus === asyncStatuses.SUCCESS) {
      form.resetFields();
    }
  }, [editRecordStatus, addRecordStatus, form]);

  useEffect(() => {
    // console.log({ fetchARecord, currentRecordData });
    form.setFieldsValue(currentRecordData);
  }, [fetchRecordStatus, currentRecordData]);

  useEffect(() => {
    // console.log({ "useEffect for currentEditViewFormId && currentFormMode": { currentEditViewFormId, currentFormMode } });
    if (
      currentEditViewFormId &&
      (currentFormMode === FormModesEnum.EDIT ||
        currentFormMode === FormModesEnum.VIEW)
    ) {
      if (!currentEditViewFormId) {
        openNotification(
          "Something went wrong",
          "Please contact developers. Id was not provided while changing modes",
          "error"
        );
        return;
      } else {
        dispatch(fetchARecord({ apiUrl: apiUrl, id: currentEditViewFormId }));
      }
    }
  }, [currentEditViewFormId, currentFormMode, dispatch]);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImage(file);
    }
  };

  const addNew = (val) => {
    // console.log(val);
    dispatch(
      addARecord({
        apiUrl: apiUrl,
        data: val,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    );
  };

  const edit = (val) => {
    // console.log(val);
    dispatch(
      editARecord({
        apiUrl,
        id: currentEditViewFormId,
        data: val,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    );
  };

  const onFinish = (val) => {
    delete val.confirm_password;
    val.username = val.email;
    val.image = image;
    if (currentFormMode === FormModesEnum.ADD) {
      addNew(val);
    } else if (currentFormMode === FormModesEnum.EDIT) {
      edit(val);
    } else {
      console.log("No suitable mode found");
    }
  };
  return (
    <>
      {contextHolder}
      <Form
        layout="vertical"
        requiredMark={true}
        onFinish={onFinish}
        autoComplete="off"
        form={form}
        disabled={currentFormMode === FormModesEnum.VIEW}
      >
        <Form.Item
          label="Type of Business"
          name="type_of_business"
          rules={[
            { required: true, message: "Please select the type of business!" },
          ]}
        >
          <Select placeholder="Select type of business">
            {TYPE_OF_BUSINESS_CHOICES.map((choice) => (
              <Option key={choice.value} value={choice.value}>
                {choice.label}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Warranty"
          name="warranty"
          rules={[{ required: false, message: "Please input the warranty!" }]}
        >
          <Input placeholder="Enter warranty details" />
        </Form.Item>

        <Form.Item
          label="Rating"
          name="rating"
          rules={[{ required: true, message: "Please input the rating!" }]}
        >
          <InputNumber
            min={0}
            max={5}
            step={0.01}
            placeholder="Enter rating (0-5)"
          />
        </Form.Item>

        <Form.Item
          label="Average Pricing"
          name="average_pricing"
          rules={[
            { required: true, message: "Please input the average pricing!" },
          ]}
        >
          <Input placeholder="Enter average pricing" />
        </Form.Item>

        <Form.Item
          label="Company Name"
          name="company_name"
          rules={[
            { required: false, message: "Please input the company name!" },
          ]}
        >
          <Input placeholder="Enter company name" />
        </Form.Item>

        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              type: "email",
              message: "Please input a valid email!",
            },
          ]}
        >
          <Input placeholder="Enter email" />
        </Form.Item>
        <Form.Item
          label="Image"
          rules={[{ required: true, message: "Please upload an image!" }]}
        >
          {/* <img src={} alt="" className="img-fluid" /> */}
          <input
            type="file"
            id="imageUpload"
            required={currentFormMode === FormModesEnum.ADD}
            onChange={handleImageChange}
          />
        </Form.Item>
        {currentFormMode === FormModesEnum.ADD ? (
          <Form.Item>
            {addRecordStatus === asyncStatuses.LOADING ? (
              <Button type="primary" htmlType="submit" disabled>
                <LoadingOutlined />
                Submitting
              </Button>
            ) : (
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            )}
          </Form.Item>
        ) : currentFormMode === FormModesEnum.EDIT ? (
          <Form.Item>
            {editRecordStatus === asyncStatuses.LOADING ? (
              <Button type="primary" htmlType="submit" disabled>
                <LoadingOutlined />
                Updating
              </Button>
            ) : (
              <Button type="primary" htmlType="submit">
                Update
              </Button>
            )}
          </Form.Item>
        ) : (
          <></>
        )}
      </Form>
    </>
  );
};
export default AddEditForm;
