import { getCommonColumns } from "Components/CustomTable/Commons";
import CustomTable from "Components/CustomTable/CustomTable";
import AddEditForm from "Components/Forms/Master/RoomTypes/AddEditForm";
import getPermittedColumns from "Utils/getPermittedColumns";
import { useSelector } from "react-redux";

const apiUrl = "/project/room-types";
const headerTitle = "Room Types Master";
const editPermission = "roomtypes:edit";
const deletePermission = "roomtypes:delete";
const addPermission = "roomtypes:add"
const columns = [
    {
        title: 'Type',
        dataIndex: 'title',
        key: 'title',
    },
    {
        title: 'Slug',
        dataIndex: 'slug',
        key: 'slug',
    },
    ...getCommonColumns({ apiUrl, editPermission, deletePermission, created: true, updated: true, action: true })
]

const RoomTypesPage = () => {

    const userPermissions = useSelector(store => store.auth?.user?.user_permissions);
    let _columns = getPermittedColumns(columns, userPermissions, editPermission, deletePermission);

    return <CustomTable
        columns={_columns}
        apiUrl={apiUrl}
        headerTitle={headerTitle}
        MyFormComponent={AddEditForm}
        addPermissionNames={[addPermission]}
    />;
}

export default RoomTypesPage;