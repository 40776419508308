import { getCommonColumns } from "Components/CustomTable/Commons";
import CustomTable from "Components/CustomTable/CustomTable";
import AddEditForm from "Components/Forms/Master/Brands/AddEditForm";
import getPermittedColumns from "Utils/getPermittedColumns";
import { useSelector } from "react-redux";

const apiUrl = "/user/rest/brand-info/";
const headerTitle = "Brands Master";
const editPermission = "brands:edit";
const deletePermission = "brands:delete";
const addPermission = "brands:add";
const columns = [
  {
    title: "Type",
    dataIndex: "type_of_business",
    key: "type_of_business",
    // render: (text) => <a>{text}</a>,
  },
  {
    title: "Ratings",
    dataIndex: "rating",
    key: "rating",
    // render: (text) => <a>{text}</a>,
  },
  {
    title: "Average Pricing",
    dataIndex: "average_pricing",
    key: "average_pricing",
    // render: (text) => <a>{text}</a>,
  },
  {
    title: "Company Name",
    dataIndex: ["company_name"],
    key: "company_name",
    // render: (text) => <a>{text}</a>,
  },
  ...getCommonColumns({
    apiUrl,
    editPermission,
    deletePermission,
    created: true,
    updated: true,
    action: true,
  }),
];

const BrandsPage = () => {
  const userPermissions = useSelector(
    (store) => store.auth?.user?.user_permissions
  );
  let _columns = getPermittedColumns(
    columns,
    userPermissions,
    editPermission,
    deletePermission
  );

  return (
    <CustomTable
      columns={_columns}
      apiUrl={apiUrl}
      headerTitle={headerTitle}
      MyFormComponent={AddEditForm}
      addPermissionNames={[addPermission]}
    />
  );
};

export default BrandsPage;
