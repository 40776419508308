import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Input,
  notification,
  Select,
  InputNumber,
  Row,
  Col,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  addARecord,
  editARecord,
  fetchARecord,
} from "Redux/MasterReducer/crudSlices";
// import apiUrls from 'Utils/apiUrls';
import { FormModesEnum, asyncStatuses } from "Redux/enums";
import { LoadingOutlined } from "@ant-design/icons";
import axios from "axios";
import { CKEditor } from "ckeditor4-react";
import { useNavigate, useParams } from "react-router";
const { Option } = Select;

const PAYMENT_CHOICES = [
  { value: "pending", label: "Pending" },
  { value: "paid", label: "Paid" },
];

const fdata = [
  {
    id: "14d5dac5-fd91-4680-a09a-1",
    service: "test1",
    amount: 100,
    description: "",
    invoice: "04fbf384-35c1-49d9-8238-b13915b910e1",
  },
  {
    id: "14d5dac5-fd91-4680-a09a-2",
    service: "test1",
    amount: 100,
    description: "",
    invoice: "04fbf384-35c1-49d9-8238-b13915b910e1",
  },
  {
    id: "14d5dac5-fd91-4680-a09a-3",
    service: "test1",
    amount: 100,
    description: "",
    invoice: "04fbf384-35c1-49d9-8238-b13915b910e1",
  },
  {
    id: "14d5dac5-fd91-4680-a09a-4",
    service: "test1",
    amount: 100,
    description: "",
    invoice: "04fbf384-35c1-49d9-8238-b13915b910e1",
  },
];

const AddInvoiceServicesForm = ({ apiUrl }) => {
  const [notificationApi, contextHolder] = notification.useNotification();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [image, setImage] = useState(null);
  const { id } = useParams();
  const [formData, setFormData] = useState([
    { invoice: id, service: "", amount: "", description: "" },
  ]);

  const openNotification = (message, description, type) => {
    notificationApi[type]({
      message: message,
      description: description,
      placement: "top",
    });
  };

  // const currentFormMode = useSelector((store) => store.master?.currentFormMode);
  // const currentEditViewFormId = useSelector(
  //   (store) => store.master?.currentEditViewFormId
  // );
  // const fetchRecordStatus = useSelector(
  //   (store) => store.master?.fetchRecordStatus
  // );
  // const editRecordStatus = useSelector(
  //   (store) => store.master?.editRecordStatus
  // );
  // const addRecordStatus = useSelector((store) => store.master?.addRecordStatus);
  // const currentRecordData = useSelector(
  //   (store) => store.master?.currentRecordData
  // );

  // useEffect(() => {
  //   if (editRecordStatus === asyncStatuses.SUCCESS) {
  //     form.resetFields();
  //   } else if (addRecordStatus === asyncStatuses.SUCCESS) {
  //     form.resetFields();
  //   }
  // }, [editRecordStatus, addRecordStatus, form]);

  // useEffect(() => {
  //   // console.log({ fetchARecord, currentRecordData });
  //   form.setFieldsValue(currentRecordData);
  // }, [fetchRecordStatus, currentRecordData]);

  // useEffect(() => {
  //   // console.log({ "useEffect for currentEditViewFormId && currentFormMode": { currentEditViewFormId, currentFormMode } });
  //   if (
  //     currentEditViewFormId &&
  //     (currentFormMode === FormModesEnum.EDIT ||
  //       currentFormMode === FormModesEnum.VIEW)
  //   ) {
  //     if (!currentEditViewFormId) {
  //       openNotification(
  //         "Something went wrong",
  //         "Please contact developers. Id was not provided while changing modes",
  //         "error"
  //       );
  //       return;
  //     } else {
  //       dispatch(fetchARecord({ apiUrl: apiUrl, id: currentEditViewFormId }));
  //     }
  //   }
  // }, [currentEditViewFormId, currentFormMode, dispatch]);

  // const handleImageChange = (event) => {
  //   const file = event.target.files[0];
  //   if (file) {
  //     setImage(file);
  //   }
  // };

  // const addNew = (val) => {
  //   // console.log(val);
  //   dispatch(
  //     addARecord({
  //       apiUrl: apiUrl,
  //       data: val,
  //       headers: {
  //         "Content-Type": "multipart/form-data",
  //       },
  //     })
  //   );
  // };

  // const edit = (val) => {
  //   // console.log(val);
  //   dispatch(
  //     editARecord({
  //       apiUrl,
  //       id: currentEditViewFormId,
  //       data: val,
  //       headers: {
  //         "Content-Type": "multipart/form-data",
  //       },
  //     })
  //   );
  // };

  const handleAdd = () => {
    setFormData([
      ...formData,
      { id: null, invoice: id, service: "", amount: "", description: "" },
    ]);
  };

  const onRemove = (index) => {
    // FIX THIS
    // setFormData([...formData.slice(0, index), ...formData.slice(index + 1)]);
  };

  const handleChange = (index, field, value) => {
    setFormData([]);
    setFormData([
      ...formData.slice(0, index),
      { ...formData[index], [field]: value },
      ...formData.slice(index + 1),
    ]);
  };

  const onSubmit = async () => {
    try {
      console.log(formData);
      const response = await axios.post(
        "/order/invoice-services/create_multiple_services/",
        formData
      );
      if (response?.data) {
        // form.resetFields();
        navigate("/order/invoices/");
      } else {
        console.log("Failed to Submit");
        openNotification(
          "Something went wrong",
          "Failed to submit data",
          "error"
        );
      }
    } catch (error) {}
  };

  const onDelete = async (id) => {
    try {
      console.log(formData);
      const response = await axios.delete(`/order/invoice-services/"${id}"/`);
      if (response?.status == 204 || response?.status == 200) {
        // form.resetFields();
        // navigate("/order/invoices/");
        getData();
      } else {
        console.log("Failed to Submit");
        openNotification(
          "Something went wrong",
          "Failed to delete data",
          "error"
        );
      }
    } catch (error) {}
  };

  const getData = () => {
    console.log("entered");
    const initialFormData = formData.map((_, index) => {
      const data = fdata[index];
      console.log(data, index);
      return {
        invoice: id,
        id: data?.id ?? null,
        service: data?.service ?? "",
        amount: data?.amount ?? "",
        description: data?.description ?? "",
      };
    });
    setFormData(initialFormData);
  };

  useEffect(() => {
    if (id) {
      getData();
    } else {
      navigate("/order/invoices");
    }
  }, [id]);

  return (
    <>
      {contextHolder}
      <div style={{ maxHeight: "80vh", overflowY: "scroll" }}>
        <div>
          {formData.map((data, index) => (
            <div style={{ display: "flex", margin: 20 }}>
              <div
                style={{
                  display: "flex",
                  background: "#f2f2f2",
                  padding: 20,
                  width: "100%",
                }}
              >
                <InvoiceServiceForm
                  key={index}
                  index={index}
                  formData={data}
                  onChange={handleChange}
                  onRemove={onRemove}
                  onDelete={onDelete}
                />
              </div>
            </div>
          ))}
        </div>
        {/* <div style={{ display: "flex", justifyContent: "center" }}> */}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "20px 0",
          }}
        >
          <Button
            type="primary"
            onClick={onSubmit}
            htmlType="submit"
            style={{ marginRight: 20 }}
          >
            Submit
          </Button>
          <Button type="primary" onClick={handleAdd}>
            Add More
          </Button>
        </div>
      </div>
    </>
  );
};

export default AddInvoiceServicesForm;

const InvoiceServiceForm = ({
  index,
  formData,
  onChange,
  onRemove,
  onDelete,
}) => {
  const [form] = Form.useForm();

  // ckeditor
  const [editorData, setEditorData] = useState("");

  const editorConfig = {
    extraPlugins: ["tableresize", "uploadimage", "createpdf"],
    removePlugins: [
      "exportpdf",
      "forms",
      "smiley",
      "language",
      "iframe",
      "about",
    ],
    // filebrowserBrowseUrl: 'http://localhost:4000/media/24b358cf-8e56-4b03-9843-29fabf589b8d/template/home-advt_jzx7KBU.jpeg',
    filebrowserImageUploadUrl:
      process.env.REACT_APP_BASE_URL + `/template/upload_template_image/`,
    fileTools_requestHeaders: axios.defaults.headers.common,
    uploadUrl:
      process.env.REACT_APP_BASE_URL + `/template/upload_template_image/`,
    contentsCss: [
      // "https://cdn.jsdelivr.net/npm/bootstrap@4.3.1/dist/css/bootstrap.min.css",
      process.env.REACT_APP_BASE_URL +
        "/staticfiles/ckeditor/4.19.0/full-all/document-style.css",
    ],
    bodyClass: "document-editor",
    // height: 900, // width: 1000,
    height: "5cm",
    // width: "18.6cm",
    width: "21cm",
    allowedContent: true,
    // extraPlugins: [ // 'timestamp' // ] // exportPdf_options: { // header_html: '<div class="styled">This is the Header</div>', // footer_html: '<div class="styled-counter"><span class="date></span></div>', // header_and_footer_css: '.styled { font-weight: bold; padding: 10px; display: flex; color: red; } .styled-counter {font-size: 10px; color: red; }', // margin_top: '2cm', // margin_bottom: '10cm', // } // exportPdf_options: { // format: "A3" // }
    exportPdf_options: {
      header_html: '<div class="styled">Header content</div>',
      footer_html: '<div class="styled"><span class="pageNumber"></span></div>',
      header_and_footer_css:
        ".styled { font-weight: bold; padding: 10px; text-align: center; color: red; }",
      margin_left: "1cm",
      margin_right: "2cm",
      format: "A5",
      page_orientation: "landscape",
    },
    protectedSource: [/{%[\s\S]?%}+/g, /{{[\s\S]?}}+/g],
    // formatOutput: false,
    // formatSource: false
  };

  useEffect(() => {
    // console.log(formData);
    form.setFieldsValue(formData);
  }, [formData]);

  return (
    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <Form
        layout="vertical"
        initialValues={formData}
        defaultValue={formData}
        style={{ maxHeight: "80vh", overflowY: "scroll" }}
      >
        <Form.Item
          label="Service"
          name="service"
          rules={[{ required: true, message: "Please input the service!" }]}
        >
          <Input
            placeholder="Enter service"
            onChange={(e) => onChange(index, "service", e.target.value)}
          />
        </Form.Item>
        <Form.Item
          label="Amount"
          name="amount"
          rules={[{ required: true, message: "Please input the amount!" }]}
        >
          <Input
            placeholder="Enter amount"
            value={formData?.amount}
            onChange={(e) => onChange(index, "amount", e.target.value)}
          />
        </Form.Item>
        <Form.Item
          label="Description"
          name="description"
          rules={[
            { required: false, message: "Please input the description!" },
          ]}
        >
          <Input
            placeholder="Enter description"
            value={formData?.description}
            onChange={(e) => onChange(index, "description", e.target.value)}
          />
        </Form.Item>
        {/* <Form.Item label="Description" name="description">
          <CKEditor
            config={editorConfig}
            editorUrl={
              process.env.REACT_APP_BASE_URL +
              "/staticfiles/ckeditor/4.19.0/full-all/ckeditor.js"
            }
            name="description"
            // initData="<p>Invoice service description</p>"
            // onChange={(e) => setEditorData(e.editor.getData())}
            onChange={(e) => onChange(index, "description", e.editor.getData())}
          />
        </Form.Item> */}
      </Form>
      {/* {index > 0 && (
        <div
          style={{ margin: "10px 0", display: "flex", justifyContent: "end" }}
        >
          <Button
            type="primary"
            onClick={() => onRemove(index)}
            style={{ marginRight: 20 }}
          >
            Remove
          </Button>
        </div>
      )} */}
      {formData.id && (
        <div
          style={{ margin: "10px 0", display: "flex", justifyContent: "end" }}
        >
          <Button
            type="primary"
            onClick={() => onDelete(formData.id)}
            style={{ marginRight: 20 }}
          >
            Delete
          </Button>
        </div>
      )}
    </div>
  );
};
