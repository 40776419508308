import {  getCommonColumns } from "Components/CustomTable/Commons";
import CustomTable from "Components/CustomTable/CustomTable";
import AddEditForm from "Components/Forms/PlatformUsers/Users/AddEditForm";
import getPermittedColumns from "Utils/getPermittedColumns";
import { useSelector } from "react-redux";

const apiUrl = "/user/rest/users";
const headerTitle = "Platform Users";
const editPermission = "users:edit";
const deletePermission = "users:delete";
const addPermission = "users:add";

const columns = [
    {
        title: 'Full Name',
        dataIndex: 'full_name',
        key: 'full_name',
        // render: (text) => <a>{text}</a>,
    },
    {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        // render: (text) => <a>{text}</a>,
    },
    {
        title: 'Mobile',
        dataIndex: 'mobile',
        key: 'mobile',
        // render: (text) => <a>{text}</a>,
    },
    ...getCommonColumns({ apiUrl, deletePermission, editPermission, created: true, updated: true, action: true })
]

const UsersPage = () => {

    const userPermissions = useSelector(store => store.auth?.user?.user_permissions);
    let _columns = getPermittedColumns(columns, userPermissions, editPermission, deletePermission);

    return <CustomTable
        columns={_columns}
        apiUrl={apiUrl}
        headerTitle={headerTitle}
        MyFormComponent={AddEditForm}
        addPermissionNames={[addPermission]}
    />;
}

export default UsersPage;