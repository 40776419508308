import React, { useEffect, useState } from 'react';
import { Button, Form, Input, notification, Select, InputNumber } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { addARecord, editARecord, fetchARecord } from 'Redux/MasterReducer/crudSlices';
// import apiUrls from 'Utils/apiUrls';
import { FormModesEnum, asyncStatuses } from 'Redux/enums';
import { LoadingOutlined } from "@ant-design/icons"
import axios from 'axios';
const { Option } = Select;

const AddEditForm = ({ apiUrl }) => {
  const [notificationApi, contextHolder] = notification.useNotification();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const openNotification = (message, description, type) => {
    notificationApi[type]({
      message: message,
      description: description,
      placement: "top"
    });
  };

  const currentFormMode = useSelector(store => store.master?.currentFormMode);
  const currentEditViewFormId = useSelector(store => store.master?.currentEditViewFormId);
  const fetchRecordStatus = useSelector(store => store.master?.fetchRecordStatus);
  const editRecordStatus = useSelector(store => store.master?.editRecordStatus);
  const addRecordStatus = useSelector(store => store.master?.addRecordStatus);
  const currentRecordData = useSelector(store => store.master?.currentRecordData);

  useEffect(() => {
    if (editRecordStatus === asyncStatuses.SUCCESS) {
      form.resetFields();
    } else if (addRecordStatus === asyncStatuses.SUCCESS) {
      form.resetFields();
    }
  }, [editRecordStatus, addRecordStatus, form]);

  useEffect(() => {
    // console.log({ fetchARecord, currentRecordData });
    form.setFieldsValue(currentRecordData);
  }, [fetchRecordStatus, currentRecordData]);

  useEffect(() => {
    // console.log({ "useEffect for currentEditViewFormId && currentFormMode": { currentEditViewFormId, currentFormMode } });
    if (currentEditViewFormId && (currentFormMode === FormModesEnum.EDIT || currentFormMode === FormModesEnum.VIEW)) {
      if (!currentEditViewFormId) {
        openNotification("Something went wrong", "Please contact developers. Id was not provided while changing modes", "error");
        return;
      } else {
        dispatch(fetchARecord({ apiUrl: apiUrl, id: currentEditViewFormId }));
      }
    }
  }, [currentEditViewFormId, currentFormMode, dispatch]);

  const addNew = (val) => {
    // console.log(val);
    dispatch(addARecord({ apiUrl: apiUrl, data: val }));
  }

  const edit = (val) => {
    // console.log(val);
    dispatch(editARecord({ apiUrl, id: currentEditViewFormId, data: val }));
  }

  const onFinish = (val) => {
    delete val.confirm_password;
    val.username = val.email;
    if (currentFormMode === FormModesEnum.ADD) {
      addNew(val);
    } else if (currentFormMode === FormModesEnum.EDIT) {
      edit(val);
    } else {
      console.log("No suitable mode found");
    }
  }
  return (
    <>
      {contextHolder}
      <Form layout="vertical"
        requiredMark={true}
        onFinish={onFinish}
        autoComplete="off"
        form={form}
        disabled={currentFormMode === FormModesEnum.VIEW}
      >
      <Form.Item
        label="Email"
        name="email"
        rules={[{ required: true, type: 'email', message: 'Please input a valid email!' }]}
      >
        <Input placeholder="Enter your email" />
      </Form.Item>

      <Form.Item
        label="Password"
        name="password"
        rules={[{ required: true, message: 'Please input your password!' }]}
      >
        <Input.Password placeholder="Enter your password" />
      </Form.Item>

      <Form.Item
        label="Company Name"
        name="company_name"
        rules={[{ required: true, message: 'Please input your company name!' }]}
      >
        <Input placeholder="Enter your company name" />
      </Form.Item>

      <Form.Item
        label="Location"
        name="location"
        rules={[{ required: true, message: 'Please input your location!' }]}
      >
        <Input placeholder="Enter your location" />
      </Form.Item>

      <Form.Item
        label="Project Count"
        name="project_count"
        rules={[{ required: true, message: 'Please input the project count!' }]}
      >
        <InputNumber min={0} placeholder="Enter project count" style={{ width: '100%' }} />
      </Form.Item>

      <Form.Item
        label="Years of Experience"
        name="years_of_experience"
        rules={[{ required: true, message: 'Please input your years of experience!' }]}
      >
        <InputNumber min={0} placeholder="Enter years of experience" style={{ width: '100%' }} />
      </Form.Item>

      <Form.Item
        label="Number of Cities"
        name="no_of_cities"
        rules={[{ required: true, message: 'Please input the number of cities!' }]}
      >
        <InputNumber min={0} placeholder="Enter number of cities" style={{ width: '100%' }} />
      </Form.Item>

      <Form.Item
        label="Job Cost Minimum"
        name="job_cost_minimum"
        rules={[{ required: true, message: 'Please input the minimum job cost!' }]}
      >
        <InputNumber
          min={0}
          step={100000}
          placeholder="Enter minimum job cost"
          style={{ width: '100%' }}
        />
      </Form.Item>

      <Form.Item
        label="Job Cost Maximum"
        name="job_cost_maximum"
        rules={[{ required: true, message: 'Please input the maximum job cost!' }]}
      >
        <InputNumber
          min={0}
          step={100000}
          placeholder="Enter maximum job cost"
          style={{ width: '100%' }}
        />
      </Form.Item>
        {
          currentFormMode === FormModesEnum.ADD ? <Form.Item>
            {
              addRecordStatus === asyncStatuses.LOADING ?
                <Button type="primary" htmlType="submit" disabled>
                  <LoadingOutlined />
                  Submitting
                </Button> :
                <Button type="primary" htmlType="submit" >
                  Submit
                </Button>
            }
          </Form.Item> : currentFormMode === FormModesEnum.EDIT ? <Form.Item>
            {
              editRecordStatus === asyncStatuses.LOADING ?
                <Button type="primary" htmlType="submit" disabled>
                  <LoadingOutlined />
                  Updating
                </Button> :
                <Button type="primary" htmlType="submit" >
                  Update
                </Button>
            }
          </Form.Item> : <></>
        }

      </Form >
    </>
  );
};
export default AddEditForm;