import { getCommonColumns } from "Components/CustomTable/Commons";
import CustomTable from "Components/CustomTable/CustomTable";
import AddEditForm from "Components/Forms/Master/Products/AddEditForm";
import getPermittedColumns from "Utils/getPermittedColumns";
import { useSelector } from "react-redux";

const apiUrl = "/product/products";
const headerTitle = "Products Master";
const editPermission = "product:edit";
const deletePermission = "product:delete";
const addPermission = "product:add";
const columns = [
  {
    title: "Name",
    dataIndex: "title",
    key: "title",
    // render: (text) => <a>{text}</a>,
  },
  {
    title: "Product Type",
    dataIndex: ["product_type_data", "title"],
    key: "product_type",
    // render: (text) => <a>{text}</a>,
  },
  {
    title: "Brand",
    dataIndex: "brand_name",
    key: "brand_name",
    // render: (text) => <a>{text}</a>,
  },
  ...getCommonColumns({
    apiUrl,
    editPermission,
    deletePermission,
    created: true,
    updated: true,
    action: true,
  }),
];

const ProductsPage = () => {
  const userPermissions = useSelector(
    (store) => store.auth?.user?.user_permissions
  );
  let _columns = getPermittedColumns(
    columns,
    userPermissions,
    editPermission,
    deletePermission
  );

  return (
    <CustomTable
      columns={_columns}
      apiUrl={apiUrl}
      headerTitle={headerTitle}
      MyFormComponent={AddEditForm}
      addPermissionNames={[addPermission]}
    />
  );
};

export default ProductsPage;
