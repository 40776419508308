import { getCommonColumns } from "Components/CustomTable/Commons";
import CustomTable from "Components/CustomTable/CustomTable";
import AddEditForm from "Components/Forms/Master/InvoiceServices/AddEditForm";
import getPermittedColumns from "Utils/getPermittedColumns";
import { notification, Table } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";

// const customApiUrl = `/order/invoice-services/?invoice=${id}`;
const apiUrl = "/order/invoice-services";
const headerTitle = "Invoices Master";
const editPermission = "invoices:edit";
const deletePermission = "invoices:delete";
const addPermission = "invoices:add";

const columns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    // render: (text) => <a>{text}</a>,
  },
  // {
  //   title: "Invoice",
  //   dataIndex: "invoice",
  //   key: "invoice",
  //   // render: (text) => <a>{text}</a>,
  // },
  {
    title: "Service",
    dataIndex: ["service"],
    key: "service",
    // render: (text) => <a>{text}</a>,
  },
  {
    title: "Amount",
    dataIndex: ["amount"],
    key: "amount",
    // render: (text) => <a>{text}</a>,
  },
  {
    title: "Description",
    dataIndex: ["description"],
    key: "description",
    // render: (text) => <a>{text}</a>,
  },
  ...getCommonColumns({
    apiUrl,
    editPermission,
    deletePermission,
    created: true,
    updated: true,
    action: true,
  }),
];

const InvoiceServicesPage = () => {
  const { id } = useParams();
  const customApiUrl = `/order/invoice-services/?invoice=${id}`;
  const navigate = useNavigate();

  const [notificationApi, contextHolder] = notification.useNotification();
  const userPermissions = useSelector(
    (store) => store.auth?.user?.user_permissions
  );

  const [invoiceData, setInvoiceData] = useState(null);

  const openNotification = (message, description, type) => {
    notificationApi[type]({
      message: message,
      description: description,
      placement: "top",
    });
  };

  let _columns = getPermittedColumns(
    columns,
    userPermissions,
    editPermission,
    deletePermission
  );

  // use this if invoice data needs to be shown
  const getData = async () => {
    const response = await axios.get(`/order/invoices/${id}/`);
    console.log(response);
    if (response?.data) {
      setInvoiceData(response?.data);
    } else {
      openNotification(
        "Something went wrong",
        "Error while fetching invoice data",
        "error"
      );
    }
  };

  useEffect(() => {
    console.log(id);
    if (id) {
      // getData();
    } else {
      navigate("/order/invoices");
    }
  }, [id]);

  return (
    <>
      {contextHolder}
      {/* {invoiceData && (
        <div>
          <h4>Order Id: {invoiceData?.order}</h4>
          <h4>Step: {invoiceData?.step}</h4>
        </div>
      )} */}
      <h3>Current Invoice Services</h3>
      <CustomTable
        columns={_columns}
        apiUrl={customApiUrl}
        headerTitle={headerTitle}
        MyFormComponent={AddEditForm}
        addPermissionNames={[addPermission]}
      />
    </>
  );
};

export default InvoiceServicesPage;
